.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 8%;
  z-index: 4;
  box-shadow: 0px 0px 20px 1px rgb(0, 0, 0, 0.4);
  color: white;
  padding: 0 0.5rem 0 1rem;
}

#root {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  --backgroundWhite: #f8f8f8;
  --backgroundGray: #49494e;
  --inputLightblue: #3ccbfb;
  --primaryPink: #ff579f;
  --primaryPurple: #ed5bc6;
  --pinkGradient: linear-gradient(
    315deg,
    var(--primaryPink) 0%,
    var(--primaryPurple) 100%
  );
  --hovergraylight: #dfdfdf;
  --hovergraydark: #434349;
  --independenceBlue: #434354;
  --errorTextColor: #ff644b;
}

body {
  height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
}

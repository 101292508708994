.dot {
  animation: flashing 1.75s infinite linear;
  border-radius: 50%;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}
.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes flashing {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.1;
  }
}

.conversationWindow {
  flex-grow: 1;
  transition: 0.3s ease-in-out;
  height: 100%;
  min-width: 100%;
  position: relative;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.6;
}

.messagesWindow {
  flex-grow: 1;
  width: 100%;
  transition: 0.3s ease-in-out;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 90vh;
}

.messagesWindow::-webkit-scrollbar {
  display: none;
}

@media only screen and (min-width: 540px) {
  .conversationWindow {
    min-width: 0%;
  }
}

.container {
  box-shadow: 0px 0px 10px 1px rgb(0, 0, 0, 0.1);
  border-top: 1px solid rgb(0, 0, 0, 0.1);
  max-width: 100vw;
  display: flex;
}

.submitBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 540px) {
}

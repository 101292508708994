.window {
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.graphicWindow {
  background: linear-gradient(
    315deg,
    rgba(120, 120, 255, 1) 0%,
    rgba(163, 163, 255, 1) 50%,
    rgba(203, 163, 255, 1) 100%
  );
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 0;
  height: 100%;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  user-select: none;
}

.formWindow {
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
  padding: 0 1rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* border-left: 5px solid var(--primaryPink); */
}

.verticalColumn {
  flex-direction: column;
  max-width: 380px;
  min-width: 240px;
  align-items: center;
}

.formHTML {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logoContainer {
  background-color: rgb(255, 255, 255, 1);
  border-radius: 50%;
  margin-top: 1rem;
  height: 60px;
  width: 60px;
  border: 1px solid rgb(0, 0, 0, 0.1);
  padding: 10px;
  box-shadow: 0px 0px 10px 1px rgb(0, 0, 0, 0.2);
  margin-bottom: 0.7rem;
  user-select: none;
}

.buttons {
  width: 100%;
  height: 2.4rem;
  border-radius: 6px;
  font-weight: 500;
  user-select: none;
  margin-bottom: 1rem;
  overflow: hidden;
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.176);
  display: flex;
  justify-content: center;
  align-items: center;
}

.submitBtn {
  background: var(--pinkGradient);
  color: white;
  position: relative;
}

.buttons:active {
  transform: translate(0px, 2px);
}

.submitBtn::after {
  content: "";
  transform: translate(0, -50%) rotate(-20deg);
  width: 42px;
  height: 140px;
  background-color: rgb(255, 255, 255, 0.3);
  position: absolute;
  top: 50%;
  left: -60px;
  transition: 0.27s ease-in-out;
}

.submitBtn:hover::after {
  left: 110%;
}

.loadingSpinner {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  from {
    transform: rotate(-360deg);
  }
}

.signupDivider {
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  opacity: 0.5;
  user-select: none;
}

.signupDivider div {
  width: 30%;
  height: 2px;
}
.signupDivider span {
  margin: 0 1rem;
}

.githubBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: 2px solid;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
}

.githubBtn:active {
  opacity: 0.4;
}
.githubBtn:hover {
  opacity: 0.4;
}

.alternateLoginLink {
  margin-top: 1rem;
  user-select: none;
}

.errorMessage {
  padding-bottom: 0.3rem;
  min-height: 1.6rem;
  transition: 0.3s ease-in-out;
  user-select: none;
  color: rgb(253, 100, 100);
  font-size: 0.9rem;
  width: 100%;
  text-align: right;
}

@media only screen and (min-width: 765px) {
  .graphicWindow {
    width: 50%;
  }

  .formWindow {
    width: 50%;
  }
}

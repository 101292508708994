.container {
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.userInfo {
  flex-direction: column;
  align-items: center;
  margin: 1rem 0 0 0;
  width: 100%;
}

.editProfileBtn {
  bottom: 0;
  right: 0;
  transform: translate(0, 25%);
  opacity: 1;
  border: 2px solid;
  border-color: rgb(255, 255, 255, 0.7);
}

.editProfileBtn:hover {
  transform: translate(0, 25%) scale(1.1);
}

.editProfileBtn:active {
  transform: translate(1px, 27%) scale(1.1);
}

.menuItem {
  border-radius: 0;
  border: 1px solid rgb(0, 0, 0, 0.05);
}

.menuItemLight:hover {
  background-color: var(--hovergraylight);
}

.menuItemDark:hover {
  background-color: #595969;
}

.copiedIndicator {
  position: absolute;
  top: 3px;
  left: 1.5rem;
  font-weight: 500;
  font-size: 0.8rem;
  opacity: 0.8;
}

@media (hover: none) {
  .menuItem:hover {
    background-color: initial;
  }
}

.convosContainer {
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding: 0.6rem 0rem 0.6rem 0rem;
  overflow-y: scroll;
}

.convosContainer::-webkit-scrollbar {
  display: none;
}

.groupControls {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.4rem;
  padding: 0 0.2rem 0 1rem;
}

.plusButton {
  margin: 0 0 0 0.5rem;
  color: "white";
  font-size: 1.7rem;
  transition: 0.1s ease-in-out;
}

.plusButton:hover {
  transform: scale(1.1);
}

@media (hover: none) {
  .plusButton:hover {
    transform: none;
  }
}

.groupTitle {
  font-weight: 600;
  font-size: 1.2rem;
}

.linkComponent {
  width: 100%;
  position: relative;
}

.linkComponentActive {
  width: 100%;
  position: relative;
}

.linkComponentActive::before {
  content: "";
  height: 96%;
  width: 6px;
  background-color: var(--primaryPink);
  top: 2%;
  left: 0;
  position: absolute;
}

.convoListItem {
  width: 100%;
  height: 4.2rem;
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  transition: 0.15s ease-in-out;
  color: inherit;
}

.convoListItem:hover {
  color: white;
}

.convoListItem::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: inherit;
  background: linear-gradient(
    45deg,
    rgba(244, 67, 54, 1),
    rgba(224, 53, 233, 1)
  );
  opacity: 0;
}

.convoListItem:hover::before {
  opacity: 1;
}

.listItemArrow {
  visibility: hidden;
  color: white;
  opacity: 0;
}

.convoListItem:hover .listItemArrow {
  visibility: visible;
  opacity: 0.7;
}

.listItemTextContainer {
  color: inherit;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  z-index: 2;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
}

.liHeader {
  color: inherit;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.convoPreviewText {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  color: inherit;
  font-size: 1rem;
  font-weight: 300;
}

.toast {
  animation: makeOpaque 4s forwards;
}

@keyframes makeOpaque {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

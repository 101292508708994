.msgContainer {
  padding: 0.5rem 0px;
  width: 100%;
  align-items: center;
}

.msgContainer:hover .date {
  opacity: 0.7;
}

.msgLight:hover {
  background-color: var(--hovergraylight);
}
.msgDark:hover {
  background-color: var(--hovergraydark);
}

.msgContainer:first-child {
  padding: 1rem 0 0.5rem 0;
}

.avatarContainer {
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
}

.avatar {
  user-select: none;
  margin: 0 0.8rem 0 0.8rem;
}

.textContainer {
  flex-direction: column;
  width: 100%;
}

.nameAndDate {
  user-select: none;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.userName {
  font-weight: bold;
  flex-grow: 1;
}

.date {
  font-size: 0.7rem;
  margin: 0 10px;
  opacity: 0;
  transition: 0.09s ease-in-out;
}

.msgText {
  max-width: 500px;
}

@media (hover: none) {
  .msgContainer:hover {
    background-color: inherit;
  }
}

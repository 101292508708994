.searchContainer {
  flex-direction: column;
  height: 100%;
  width: 100%;
  left: 0;
  position: absolute;
  z-index: 2;
  box-shadow: 0px 0px 20px 1px rgb(0, 0, 0, 0.7);
  align-items: center;
  padding: 0.5rem 0rem;
  overflow-x: hidden;
  overflow-y: visible;
}

.searchBox {
  width: 94%;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 1rem;
}
